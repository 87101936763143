<template>
  <div>
    <GenericForm
      title="Médicos"
      previousRoute="medico"
      editRoute="medicoEditar"
      :model="model"
      :route="medicoRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_medico"
      :permissionsToWrite="['rw_vinculo_externo']"
      :permissionsToEdit="['re_vinculo_externo']"
      :permissionsToDelete="['rd_vinculo_externo']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Médico*" label-for="nome">
              <b-form-input
                id="nome"
                v-model="model.nome"
                autocomplete="off"
                class="invision-input"
                name="nome"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('nome')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="CRM" label-for="crm">
              <b-form-input
                id="crm"
                v-model="model.crm"
                autocomplete="off"
                class="invision-input"
                name="crm"
                :disabled="isBusy"
              ></b-form-input>
            </b-form-group>
          </b-col>
          </b-row>
          <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Telefone" label-for="telefone">
              <b-form-input
                id="telefone"
                v-model="model.telefone"
                autocomplete="off"
                class="invision-input"
                name="telefone"
                v-mask="['(##)####-####', '(##)#####-####']"
                v-validate="'validaPhone'"
                :state="validateState('telefone')"
                :disabled="isBusy"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

import GenericForm from '@/components/Form/GenericForm';

export default {
  directives: {
    mask,
  },

  components: {
    GenericForm,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_medico: this.$route.params.id || -1,
        nome: '',
        telefone: '',
      },
      medicoRoute: 'medico',
    };
  },

  methods: {
    updateModel(model) {
      this.model = model;
    },
  },
};
</script>
